import React from 'react';
import { Link } from 'react-router-dom';
import FormOne from '../contact/FormOne';

const BannerFive = ({ scrollToSection }) => {
    return (
        <div className="banner banner-style-5" style={{ position: 'relative', overflow: 'hidden' }}>
            {/* Background Video */}
            <video
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            >
                <source src="images/banner/globe.mp4" type="video/mp4" />
            </video>
            
            {/* Content */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-9">
                        <div className="banner-content">
                            <h1 className="title">Making Smart Solutions</h1>
                            <p>Developing seamless, scalable software solutions that are precisely tailored to your business needs. We prioritize delivering high-quality results on time, while ensuring the project scope remains well-defined and consistently maintained throughout the process.</p>
                            <div>
                                <Link onClick={() => scrollToSection('section1')} className="axil-btn btn-fill-white btn-large">
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">
                        <div className="banner-form">
                            {/* Uncomment if needed */}
                            {/* <div className="contact-form-box shadow-box">
                                <h3 className="title">Get a free Keystroke quote now</h3>
                                <FormOne />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerFive;
